import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { loadUsers, removeUser, exportCsv, importCsv, countUsers } from '../../actions/users';
import { loadRoles } from '../../actions/roles';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { sendMessageError, sendMessageSuccess } from "../../actions/toast";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TableContainer } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

const pasTable = 10;

export default class Users extends React.Component {
  state = {
    roles: [],
    users: [],
    count: 0,
    filtreNom: "",
    filtreRole: "",
    offset: 0,
    idRole: "",
    currentPage: 0,
  };

  componentDidMount() {
    const that = this;
    that.__searchWithFilter();

    loadRoles()
      .then(roles => {
        //On récupère le nombre d'utilisateur
        countUsers()
          .then((count) => {
            this.setState({
              count,
              roles
            });
          })
          .catch(error => sendMessageError(error));
      })
      .catch(error => sendMessageError(error))
  }

  handleFilterPrefix = (e) => {
    this.setState({
      filtreNom: e.target.value,
      offset: 0
    }, () => this.__searchWithFilter(this.state.offset, this.state.filtreRole, this.state.filtreNom));
  };

  handleFilterRole = (e, key, idRole) => {
    this.setState({
      idRole,
      offset: 0,
      filtreRole: e.target.value,
    }, () => this.__searchWithFilter(this.state.offset, this.state.filtreRole, this.state.filtreNom));
  };

  __searchWithFilter = (offset, filtreRole, filtreNom) => {
    if (offset === undefined) {
      offset = this.state.offset;
    }

    if (filtreRole === undefined) {
      filtreRole = this.state.filtreRole;
    }

    if (filtreNom === undefined) {
      filtreNom = this.state.filtreNom;
    }

    const realIdRole = filtreRole === "0" ? "" : filtreRole;

    loadUsers(offset, realIdRole, filtreNom)
      .then(users => {
        this.setState({
          users
        })
      })
      .catch(error => sendMessageError(error));
  };

  handleRemoveUser = (idUser) => {
    const that = this;
    removeUser(idUser)
      .then(() => {
        countUsers()
          .then(count => {
            this.setState({count, offset: 0}, () => {
              that.__searchWithFilter();
              sendMessageSuccess("Utilisateur bien supprimé");
            });
          })
          .catch(error => sendMessageError(error));
      })
      .catch(error => sendMessageError(error));
  };

  handlePage = (e, newPage) => {
    this.setState({
      currentPage: newPage,
      offset: pasTable * newPage,
    }, () => this.__searchWithFilter(this.state.offset, this.state.filtreRole, this.state.filtreNom));
  };

  handleExportCsv = () => {
    exportCsv()
      .catch((error) => sendMessageError(error));
  };

  _importCsv = (e) => {
    e.preventDefault();

    let file = e.target.files[0];

    importCsv(file)
      .then(() => sendMessageSuccess("Import bien effectué, rechargez la page pour voir les modifications"))
      .catch(() => sendMessageError("Erreur lors de l'importation des données"));
  };

  render() {
    let data = [];
    let filtersRoles = [
      <MenuItem
        key="0"
        value="0"
      >
        Tout
      </MenuItem>
    ];

    const { users, roles } = this.state;

    if (roles?.length > 0) {
      for (const role of roles) {
        filtersRoles.push(
          <MenuItem
            key={role._id}
            value={role._id}
          >
            {role.nom}
          </MenuItem>
        );
      }
    }

    if (users?.length > 0) {
      for (const user of users) {

        const roleName = user.role ? user.role.nom_role : '';

        data.push(
          <TableRow key={user._id}>
            <TableCell>{user.nom}</TableCell>
            <TableCell>{user.prenom}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{roleName}</TableCell>
            <TableCell style={{ width: 120 }}>
              <Button onClick={() => this.props.history.push("/users/" + user._id + "/update")}>Détails</Button>
            </TableCell>
            <TableCell style={{ width: 140 }}>
              <Button color="secondary" onClick={() => this.handleRemoveUser(user._id)}>Supprimer</Button>
            </TableCell>
          </TableRow>
        );
      }
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Liste des utilisateurs
          </h1>
        </section>
        <section className="content">
          <div className="row mb-20">
            <div className="col-md-12">
              <Button color="primary" onClick={() => this.props.history.push("/users/add")}>Ajouter un utilisateur</Button>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-12 mb15">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Filtres</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-3">
                      <FormControl>
                        <InputLabel>Rôle</InputLabel>
                        <Select
                          value={this.state.filtreRole}
                          onChange={this.handleFilterRole}
                        >
                          {filtersRoles}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      <TextField
                        label="Saisissez un préfix"
                        value={this.state.filtreNom}
                        onChange={this.handleFilterPrefix}
                      />
                    </div>
                    <div className="col-md-4 pull-right">
                      <Button color="primary" onClick={this.handleExportCsv}>Exporter CSV</Button>
                      <input
                        color="primary"
                        accept="*"
                        type="file"
                        onChange={this._importCsv}
                        id="import-csv"
                        style={{ display: 'none', }}
                      />
                      <label htmlFor="import-csv">
                        <Button
                          variant="contained"
                          component="span"
                          size="large"
                          color="primary"
                        >
                          Importer CSV
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Paper>
                <TableContainer>
                  <Table selectable={"false"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>Prénom</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell style={{ width: 120 }}></TableCell>
                        <TableCell style={{ width: 140 }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className='row'>
                  <div className='col-md-12'>
                    <TablePagination
                      rowsPerPageOptions={[pasTable]}
                      component="div"
                      count={this.state.count}
                      rowsPerPage={pasTable}
                      page={this.state.currentPage}
                      onChangePage={this.handlePage}
                    />
                  </div>
                  {
                    this.state.count > pasTable && <div className='col-md-2 col-md-offset-10'>
                      <Button color="primary" onClick={() => {
                        this.handlePage(null, Math.round(this.state.count / pasTable) - 1)
                      }}>
                        Aller à la dernière page
                      </Button>
                    </div>
                  }

                </div>
              </Paper>
            </div>
          </div>
        </section>
      </div>

    )
  }
}
