import React from 'react';
import Button from '@material-ui/core/Button';
import {
  loadRisques,
  removeRisque,
  exportCsv,
  importCsv,
  countRisques
} from '../../actions/risques';
import { loadCategoriesRisque } from '../../actions/categoriesRisque';
import { loadTypesRisque } from '../../actions/typesRisque';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { sendMessageError, sendMessageSuccess } from "../../actions/toast";
import moment from 'moment';
import { TableContainer } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

const pasTable = 10;

export default class Risques extends React.Component {
  state = {
    risques: [],
    categoriesRisque: [],
    typesRisque: [],
    count: 0,
    idCategorieRisque: "",
    idTypeRisque: "",
    offset: 0,
    currentPage: 0,
  };

  componentDidMount() {
    const that = this;

    this.__loadRisques();

    loadCategoriesRisque()
      .then((categoriesRisque) => {
        that.setState({
          categoriesRisque,
        });
      });

    loadTypesRisque()
      .then((typesRisque) => {
        that.setState({
          typesRisque,
        });
      });

    //On récupère le nombre de risque
    countRisques()
      .then((count) => {
        this.setState({
          count,
        });
      })
      .catch(error => sendMessageError(error));
  }

  __loadRisques = (offset, idCategorieRisque, idTypeRisque) => {
    if (idCategorieRisque === undefined) {
      idCategorieRisque = this.state.idCategorieRisque;
    }

    if (idTypeRisque === undefined) {
      idTypeRisque = this.state.idTypeRisque;
    }

    if (idCategorieRisque === "0") {
      idCategorieRisque = "";
    }

    if (idTypeRisque === "0") {
      idTypeRisque = "";
    }

    if (offset === undefined) {
      offset = this.state.offset;
    }

    loadRisques(offset, idCategorieRisque, idTypeRisque)
      .then((risques) => {
        this.setState({
          risques,
        });
      })
      .catch(error => sendMessageError(error));
  };

  setOffset = (offset, callback) => {
    this.setState({
      offset
    }, () => {
      if(callback) {
        callback();
      }
    });
  }

  handleRemoveRisque = (idRisque) => {
    removeRisque(idRisque)
      .then(() => {
        this.__loadRisques();
        sendMessageSuccess("Risque bien supprimé")
      })
      .catch(error => sendMessageError(error));
  };

  handleFilterCategorieRisque = (e) => {
    this.setOffset(0, () => {
      this.setState({
        idCategorieRisque: e.target.value
      }, () => this.__loadRisques(this.state.offset, this.state.idCategorieRisque, this.state.idTypeRisque));
    });
    
  };

  handleFilterTypeRisque = (e) => {
    this.setOffset(0, () => {
      this.setState({
        idTypeRisque: e.target.value
      }, () => this.__loadRisques(this.state.offset, this.state.idCategorieRisque, this.state.idTypeRisque));
    });
  };

  handleExportCsv = () => {
    exportCsv()
      .catch(error => sendMessageError(error));
  };


  /****** IMPORT ****/
  _importCsv = (e) => {
    e.preventDefault();

    let file = e.target.files[0];

    importCsv(file)
      .then(() => sendMessageSuccess("Import bien effectué, rechargez la page pour voir les modifications"))
      .catch(() => sendMessageError("Erreur lors de l'importation des données"));
  };

  handlePage = (e, newPage) => {
    this.setState({
      currentPage: newPage,
      offset: pasTable * newPage,
    }, () => this.__loadRisques(this.state.offset, this.state.idCategorieRisque, this.state.idTypeRisque));
  };

  render() {
    const { risques, categoriesRisque, typesRisque, count } = this.state;
    let data = [];
    let filtersCategoriesRisque = [
      <MenuItem
        key="0"
        value="0"
      >
        Toutes les catégories
      </MenuItem>
    ];
    let filtersTypesRisque = [
      <MenuItem
        key="0"
        value="0"
      >
        Tous les types de risque
      </MenuItem>
    ];

    for (const categorieRisque of categoriesRisque) {
      if (!categorieRisque) {
        continue;
      }

      filtersCategoriesRisque.push(
        <MenuItem
          key={categorieRisque._id}
          value={categorieRisque._id}
        >
          {categorieRisque.nom}
        </MenuItem>
      );
    }

    for (const typeRisque of typesRisque) {
      if (!typeRisque) {
        continue;
      }

      filtersTypesRisque.push(
        <MenuItem
          key={typeRisque._id}
          value={typeRisque._id}
        >
          {typeRisque.nom}
        </MenuItem>
      );
    }

    if (risques?.length > 0) {
      for (const risque of risques) {
        const typeRisque = risque.type_risque ? risque.type_risque.nom_type_risque : '';
        const categorieRisque = risque.categorie_risque ? risque.categorie_risque.nom_categorie_risque : '';
        
        data.push(
          <TableRow key={risque.id_user + risque.date_created}>
            <TableCell>{moment(risque.date_created).format("DD-MM-YYYY HH:mm")}</TableCell>
            <TableCell>{risque.nom + " " + risque.prenom}</TableCell>
            <TableCell>{typeRisque}</TableCell>
            <TableCell>{categorieRisque}</TableCell>
            <TableCell>
              <Button onClick={() => this.props.history.push("/risques/"+risque._id+"/update")}>
                Détail
              </Button>
            </TableCell>
            <TableCell>
              <Button color="secondary" onClick={() => this.handleRemoveRisque(risque._id)}>
                Supprimer
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Liste des risques
          </h1>
        </section>
        <section className="content">
          <div className="row mb-20">
            <div className="col-md-12">
              <Button color="primary" onClick={() => this.props.history.push("/risques/add")}>
                Ajouter un risque
              </Button>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-12 mb15">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Filtres</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-3">
                      <FormControl>
                        <InputLabel>Catégorie de risque</InputLabel>
                        <Select
                          value={this.state.idCategorieRisque}
                          onChange={this.handleFilterCategorieRisque}
                        >
                          {filtersCategoriesRisque}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-3">
                      <FormControl>
                        <InputLabel>Type de risque</InputLabel>
                        <Select
                          value={this.state.idTypeRisque}
                          onChange={this.handleFilterTypeRisque}
                        >
                          {filtersTypesRisque}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4 pull-right">
                      <Button color="primary" onClick={this.handleExportCsv}>Exporter CSV</Button>
                      <input
                        color="primary"
                        accept="*"
                        type="file"
                        onChange={this._importCsv}
                        id="import-csv"
                        style={{ display: 'none', }}
                      />
                      <label htmlFor="import-csv">
                        <Button
                          variant="contained"
                          component="span"
                          size="large"
                          color="primary"
                        >
                          Importer CSV
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
            <Paper>
                <TableContainer>
                  <Table selectable={"false"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Utilisateur</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Catégorie</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className='row'>
                  <div className='col-md-12'>
                    <TablePagination
                      rowsPerPageOptions={[pasTable]}
                      component="div"
                      count={count}
                      rowsPerPage={pasTable}
                      page={this.state.currentPage}
                      onChangePage={this.handlePage}
                      showLastButton={true}
                    />
                  </div>
                  {
                    count > pasTable && <div className='col-md-2 col-md-offset-10'>
                      <Button color="primary" onClick={() => {
                        this.handlePage(null, Math.round(count / pasTable) - 1)
                      }}>
                        Aller à la dernière page
                      </Button>
                    </div>
                  }
                </div>
              </Paper>
            </div>
          </div>
        </section>
      </div>

    )
  }
}