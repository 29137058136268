import React from 'react';
import Button from '@material-ui/core/Button';
import {
  loadEtablissements,
  removeEtablissement,
  exportCsv,
  importCsv,
  countEtablissements
} from '../../actions/etablissements';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { sendMessageError, sendMessageSuccess } from "../../actions/toast";
import TextField from '@material-ui/core/TextField';
import { TableContainer } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';

const pasTable = 10;

export default class Etablissements extends React.Component {

  state = {
    etablissements: [],
    count: 0,
    offset: 0,
    filtreNom: "",
    currentPage: 0,
  }

  componentDidMount() {
    //On charge les établissements
    this.__loadEtablissement();

    //On récupère le nombre d'établissement
    countEtablissements()
      .then(count => {
        this.setState({
          count,
        });
      })
      .catch(error => sendMessageError(error));
  }

  __loadEtablissement = (offset, filtreNom) => {
    if (offset === undefined) {
      offset = this.state.offset;
    }

    if (filtreNom === undefined) {
      filtreNom = this.state.filtreNom;
    }

    loadEtablissements(offset, filtreNom)
      .then((etablissements) => {
        this.setState({
          etablissements,
        });
      })
      .catch(error => sendMessageError(error));
  };

  setOffset = (offset) => {
    this.setState({
      offset
    });
  }

  handleRemoveEtablissement = (idEtablissement) => {
    removeEtablissement(idEtablissement)
      .then(() => {
        this.__loadEtablissement();
        sendMessageSuccess("Etablissement bien supprimé")
      })
      .catch(error => sendMessageError(error));
  };

  handleFilterNom = (e) => {
    this.setState({
      filtreNom: e.target.value,
      offset: 0
    }, () => this.__loadEtablissement(this.state.offset, this.state.filtreNom));
  };

  handleExportCsv = () => {
    exportCsv()
      .catch(error => sendMessageError(error));
  };

  _importCsv = (e) => {
    e.preventDefault();

    let file = e.target.files[0];

    importCsv(file)
      .then(() => sendMessageSuccess("Import bien effectué, rechargez la page pour voir les modifications"))
      .catch(() => sendMessageError("Erreur lors de l'importation des données"));
  };

  handlePage = (e, newPage) => {
    this.setState({
      currentPage: newPage,
      offset: pasTable * newPage,
    }, () => this.__loadEtablissement(this.state.offset, this.state.filtreNom));
  };

  render() {
    let data = [];
    let buttonsControls = [];
    const { etablissements, count } = this.state;
    const { offset } = this.state;
    const positionListe = offset + pasTable > count ? count : offset + pasTable;

    if (etablissements && etablissements.length > 0) {
      for (const etablissement of etablissements) {
        if (!etablissement) {
          continue;
        }

        const type = etablissement.type_etablissement ? etablissement.type_etablissement.nom_type_etablissement : '';

        data.push(
          <TableRow key={etablissement._id}>
            <TableCell>{type}</TableCell>
            <TableCell>{etablissement.nom_etablissement}</TableCell>
            <TableCell>{etablissement.adresse}</TableCell>
            <TableCell>{etablissement.telephone}</TableCell>
            <TableCell>
              <Button onClick={() => this.props.history.push("/etablissements/" + etablissement._id + "/update")}>
                Détail
              </Button>
            </TableCell>
            <TableCell>
              <Button
                color="secondary"
                onClick={() => this.handleRemoveEtablissement(etablissement._id)}>
                Supprimer
              </Button>
            </TableCell>
          </TableRow>
        );
      }
    }

    if (offset > 0) {
      buttonsControls.push(
        <Button key="btn_precedent" onClick={this.handlePrecedent}>Précédent</Button>
      );
    }

    if (etablissements.length >= pasTable) {
      buttonsControls.push(
        <Button key="btn_suivant" onClick={this.handleSuivant} >Suivant</Button>
      );
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            Liste des établissements
          </h1>
        </section>
        <section className="content">
          <div className="row mb-20">
            <div className="col-md-12">
              <Button color="primary" onClick={() => this.props.history.push("etablissements/add")}>
                Ajouter un établissement
              </Button>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-12 mb15">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">Filtres</h3>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-4">
                      <TextField
                        label="Saisissez un nom d'établissement"
                        value={this.state.filtreNom}
                        onChange={this.handleFilterNom}
                      />
                    </div>
                    <div className="col-md-4 pull-right">
                      <Button color="primary" onClick={this.handleExportCsv}>Exporter CSV</Button>
                      <input
                        color="primary"
                        accept="*"
                        type="file"
                        onChange={this._importCsv}
                        id="import-csv"
                        style={{ display: 'none', }}
                      />
                      <label htmlFor="import-csv">
                        <Button
                          variant="contained"
                          component="span"
                          size="large"
                          color="primary"
                        >
                          Importer CSV
                        </Button>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Paper>
                <TableContainer>
                  <Table selectable={"false"}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Nom</TableCell>
                        <TableCell>Adresse</TableCell>
                        <TableCell>Telephone</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className='row'>
                  <div className='col-md-12'>
                    <TablePagination
                      rowsPerPageOptions={[pasTable]}
                      component="div"
                      count={count}
                      rowsPerPage={pasTable}
                      page={this.state.currentPage}
                      onChangePage={this.handlePage}
                    />
                  </div>
                  {
                    count > pasTable && <div className='col-md-2 col-md-offset-10'>
                      <Button color="primary" onClick={() => {
                        this.handlePage(null, Math.round(count / pasTable) - 1)
                      }}>
                        Aller à la dernière page
                      </Button>
                    </div>
                  }

                </div>
              </Paper>
            </div>
          </div>
        </section>
      </div>

    )
  }
}
